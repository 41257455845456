import {Property} from '@Shared/classes/property/property';
import {SearchSummary} from '@Shared/classes/search-summary/search-summary';
import {nameof} from '@Shared/helpers/name-of-helpers/name-of-helpers';

export const nzPropertyData = {
  propertySummarySection: [
    nameof<Property>('bedrooms'),
    nameof<Property>('bathrooms'),
    nameof<Property>('carSpaces'),
    nameof<Property>('landArea'),
    nameof<Property>('floorArea'),
    nameof<Property>('decadeBuilt'),
    nameof<Property>('dwellings'),
    nameof<Property>('wallConstruction'),
    nameof<Property>('roofConstruction'),
    nameof<Property>('saleDate'),
    nameof<Property>('salePrice'),
    nameof<Property>('saleTenure'),
    nameof<Property>('capitalValue'),
    nameof<Property>('landValue'),
    nameof<Property>('landUse'),
    nameof<Property>('landZone'),
    nameof<Property>('localAuthority'),
    nameof<Property>('propertyType'),
    nameof<Property>('titleNumber'),
    nameof<Property>('owners'),
    nameof<Property>('valuationReference'),
  ],
  propertyInformationSection: [],
  cardTileFallback: [
    nameof<SearchSummary>('capitalValue'),
    nameof<SearchSummary>('landValue'),
    nameof<SearchSummary>('saleDate'),
    nameof<SearchSummary>('salePrice'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('decadeBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('roofConstruction'),
    nameof<SearchSummary>('wallConstruction'),
  ],
  cardTileSearchPage: [
    nameof<SearchSummary>('capitalValue'),
    nameof<SearchSummary>('landValue'),
    nameof<SearchSummary>('saleDate'),
    nameof<SearchSummary>('salePrice'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('decadeBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('roofConstruction'),
    nameof<SearchSummary>('wallConstruction'),
  ],
  cardTileCMAPropertiesSold: [
    nameof<SearchSummary>('capitalValue'),
    nameof<SearchSummary>('landValue'),
    nameof<SearchSummary>('saleDate'),
    nameof<SearchSummary>('salePrice'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('decadeBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('roofConstruction'),
    nameof<SearchSummary>('wallConstruction'),
    nameof<SearchSummary>('distanceFrom'),
  ],
  cardTileCMAPropertiesOnMarket: [
    nameof<SearchSummary>('capitalValue'),
    nameof<SearchSummary>('landValue'),
    nameof<SearchSummary>('listingStartDate'),
    nameof<SearchSummary>('listingPriceView'),
    nameof<SearchSummary>('saleDate'),
    nameof<SearchSummary>('salePrice'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('decadeBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('roofConstruction'),
    nameof<SearchSummary>('wallConstruction'),
    nameof<SearchSummary>('distanceFrom'),
  ],
  cardTileRentalCMAPropertiesForLease: [
    nameof<SearchSummary>('weeklyListedPrice'),
    nameof<SearchSummary>('listingStartDate'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('decadeBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('roofConstruction'),
    nameof<SearchSummary>('wallConstruction'),
    nameof<SearchSummary>('carSpaces'),
    nameof<SearchSummary>('distanceFrom'),
  ],
  cardTileSalesCMAProperty: [
    nameof<SearchSummary>('capitalValue'),
    nameof<SearchSummary>('landValue'),
    nameof<SearchSummary>('saleDate'),
    nameof<SearchSummary>('salePrice'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('decadeBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('roofConstruction'),
    nameof<SearchSummary>('wallConstruction'),
  ],
  cardTileRentalCMAProperty: [
    nameof<SearchSummary>('weeklyListedPrice'),
    nameof<SearchSummary>('listingStartDate'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('decadeBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('roofConstruction'),
    nameof<SearchSummary>('wallConstruction'),
    nameof<SearchSummary>('carSpaces'),
  ],
  listViewSearchPage: {
    largeScreens: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('territorialAuthority'),
      nameof<SearchSummary>('capitalValue'),
      nameof<SearchSummary>('landValue'),
      nameof<SearchSummary>('floorArea'),
      nameof<SearchSummary>('landArea'),
      nameof<SearchSummary>('bedrooms'),
      nameof<SearchSummary>('bathrooms'),
      nameof<SearchSummary>('decadeBuilt'),
      nameof<SearchSummary>('saleDate'),
      nameof<SearchSummary>('salePrice'),
      nameof<SearchSummary>('valuation'),
    ],
    mediumScreens: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('territorialAuthority'),
      nameof<SearchSummary>('capitalValue'),
      nameof<SearchSummary>('landValue'),
      nameof<SearchSummary>('floorArea'),
      nameof<SearchSummary>('landArea'),
      nameof<SearchSummary>('bedrooms'),
      nameof<SearchSummary>('bathrooms'),
      nameof<SearchSummary>('decadeBuilt'),
      nameof<SearchSummary>('valuation'),
    ],
    mediumSmallScreens: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('territorialAuthority'),
      nameof<SearchSummary>('capitalValue'),
      nameof<SearchSummary>('floorArea'),
      nameof<SearchSummary>('landArea'),
      nameof<SearchSummary>('bedrooms'),
      nameof<SearchSummary>('valuation'),
    ],
    smallScreens: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('territorialAuthority'),
      nameof<SearchSummary>('capitalValue'),
    ],
  },
  listViewSearchPageV2: {
    default: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('saleDate'),
      nameof<SearchSummary>('salePrice'),
      nameof<SearchSummary>('bedrooms'),
      nameof<SearchSummary>('landArea'),
      nameof<SearchSummary>('floorArea'),
      nameof<SearchSummary>('decadeBuilt'),
      nameof<SearchSummary>('owners'),
    ],
    forSale: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('listingStartDate'),
      nameof<SearchSummary>('listingPrice'),
      nameof<SearchSummary>('bedrooms'),
      nameof<SearchSummary>('landArea'),
      nameof<SearchSummary>('floorArea'),
      nameof<SearchSummary>('decadeBuilt'),
      nameof<SearchSummary>('owners'),
    ],
    withdrawn: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('listingWithdrawnDate'),
      nameof<SearchSummary>('listingStartDate'),
      nameof<SearchSummary>('listingPrice'),
      nameof<SearchSummary>('bedrooms'),
      nameof<SearchSummary>('landArea'),
      nameof<SearchSummary>('floorArea'),
      nameof<SearchSummary>('decadeBuilt'),
      nameof<SearchSummary>('owners'),
    ],
  },
};
