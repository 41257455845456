import {PropertyDocumentType} from '@Shared/interfaces/property-document-type';

export const documentTypesNZ: PropertyDocumentType[] = [
  {
    documentTypeName: 'Listing/Agency Agreement',
  },
  {
    documentTypeName: 'Sales CMA',
  },
  {
    documentTypeName: 'Marketing',
  },
  {
    documentTypeName: 'Sale & Purchase Agreement',
  },
  {
    documentTypeName: 'Certificate of Title',
  },
  {
    documentTypeName: 'Other Instrument Title',
  },
  {
    documentTypeName: 'Other',
  },
];
